<template>
  <n-auth-panel>
    <template v-slot:heading>
      <n-html :text="$t('auth.reset_password.title')" />
    </template>

    <div v-if="!form_states.password_reset_token">
      <div v-if="form_states.reset">
        <n-paragraph>
          <n-html :text="$t('auth.reset_password.success')" />
        </n-paragraph>

        <n-button-regular
          class="mt-11"
          full-width
          @click.native="$router.push({ name: 'auth.login' })"
        >
          <n-html :text="$t('auth.reset_password.success_button')" />
        </n-button-regular>
      </div>

      <div class="flex flex-col space-y-2" v-else>
        <n-paragraph>
          <n-html :text="$t('auth.reset_password.subtitle')" />
        </n-paragraph>

        <n-form-group
          id="input-group-password"
          :label="$t('form.password_new.label')"
          label-for="input-password"
          :invalid-feedback="$t('form.password_new.feedback.invalid')"
          :state="form_states.password"
        >
          <n-form-input-password
            id="input-password"
            :placeholder="$t('form.password_new.placeholder')"
            v-model="password"
          />
        </n-form-group>

        <n-form-group
          id="input-group-password-2"
          :label="$t('form.password_new_repeat.label')"
          label-for="input-password-2"
          :invalid-feedback="$t('form.password_new_repeat.feedback.invalid')"
          :state="form_states.password_repeat"
        >
          <n-form-input-password
            id="input-password-2"
            :placeholder="$t('form.password_new_repeat.placeholder')"
            v-model="password_repeat"
            v-on:keyup.enter.native="reset_password"
          />
        </n-form-group>

        <n-form-group
          id="button-group-reset"
          :invalid-feedback="$t('auth.reset_password.error')"
          :state="form_states.reset"
        >
          <n-button-regular
            full-width
            @click.native="reset_password"
            :disabled="!valid"
          >
            <n-html :text="$t('auth.reset_password.button')" />
          </n-button-regular>
        </n-form-group>
      </div>
    </div>

    <div v-else>
      <n-paragraph>
        <n-html :text="$t('auth.reset_password.token_error')" />
      </n-paragraph>

      <n-button-regular
        class="mt-11"
        full-width
        @click.native="$router.push({ name: 'auth.forgot_password' })"
      >
        <n-html :text="$t('auth.reset_password.token_error_button')" />
      </n-button-regular>
    </div>
  </n-auth-panel>
</template>

<script>
import validation from "../../mixins/validation";

export default {
  name: "pages.auth.reset_password",
  mixin: [validation],
  data: () => ({
    password: "",
    password_repeat: "",
    password_reset_token: "",
    form_states: {
      password_reset_token: null,
      password: null,
      password_repeat: null,
      reset: null
    }
  }),
  computed: {
    valid() {
      return !!(
        this.password_reset_token &&
        this.password &&
        this.validatePassword(this.password) &&
        this.password === this.password_repeat
      );
    }
  },
  methods: {
    reset_password() {
      if (this.valid) {
        this.form_states.reset = null;
        this.$store
          .dispatch("user/set_password", {
            password_reset_token: this.password_reset_token,
            password: this.password
          })
          .then(() => {
            this.form_states.reset = true;
          })
          .catch(() => (this.form_states.reset = false));
      }
    }
  },
  watch: {
    password(n) {
      this.form_states.password = n && this.validatePassword(n);
    },
    password_repeat(n) {
      this.form_states.password_repeat = n === this.password;
    }
  },
  created() {
    if ("password_reset_token" in this.$route.query) {
      this.password_reset_token = this.$route.query.password_reset_token;
      this.$store
        .dispatch("user/validatePasswordResetToken", {
          token: this.password_reset_token
        })
        .then(() => {
          this.form_states.password_reset_token = false;
        })
        .catch(error => {
          if (error === "INVALID_TOKEN") {
            this.$router.push({ name: "auth.register" });
          } else if (error === "EXPIRED_TOKEN") {
            this.form_states.password_reset_token = true;
          } else {
            this.$router.push({ name: "auth.register" });
          }
        });
    } else {
      this.form_states.password_reset_token = true;
    }
  }
};
</script>
